<style scoped lang="less">
  .details-search {
    .back {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: center;
    }
    .input {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }
    .search-btn {
      cursor: pointer;
      height: 40px;
      line-height: 40px;
      padding: 0 15px;
      color: #FFF;
    }
  }
</style>

<style lang="less">
  .details-search {
    .fm-input-new {
      width: 50%; height: 40px;
    }
    .fm-input-new-append {
      padding: 0 !important;
      height: 40px !important;
      line-height: 40px !important;
    }
  }
</style>

<template>
  <div class="details-search">
    <router-link :to="{ name: 'resume.index' }" tag="div" class="back">
      <i class="iconfont icon-back"></i>
      <span>搜索列表</span>
    </router-link>
    <div class="input">
      <fm-input-new v-model="key" @enten="loadData" placeholder="请输入员工姓名/手机号">
        <div @click="loadData" class="search-btn theme-bg-color" slot="append">搜索</div>
      </fm-input-new>
    </div>
    <fm-modal v-model="status.choose" title="请选择">
      <WorkerList @choose="onChoose" :data-list="chooseList" style="max-height: 40vh;overflow-y: auto;" />
    </fm-modal>
  </div>
</template>

<script>
import WorkerList from '@/views/resume/components/details/list'
import { getWorkerList } from '@/views/resume/libs'

export default {
  components: { WorkerList },
  data () {
    return {
      key: null,
      chooseList: [],
      status: {
        choose: false
      }
    }
  },
  methods: {
    onChoose (data) {
      this.status.choose = false
      this.$router.push({
        name: 'resume.details.base',
        query: { id: data.id }
      })
    },
    async loadData () {
      const workerList = await getWorkerList()
      if (this.key) {
        const list = workerList.filter(v => (v.name && v.name.indexOf(this.key) > -1) || (v.phone && v.phone.indexOf(this.key) > -1))
        if (!list.length) {
          this.$Message.warning('查无此人')
        } else if (list.length === 1) {
          this.$router.push({
            name: 'resume.details.base',
            query: { id: list[0].id }
          })
        } else {
          this.chooseList = list
          this.status.choose = true
        }
      }
    }
  }
}
</script>
