<style scoped lang="less">
  .worker-list {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & + .item {
        margin-top: 10px;
      }
    }
    .left {
      display: flex;
      align-items: center;
      .name {
        margin-left: 10px;
      }
    }
  }
</style>

<template>
  <div class="worker-list">
    <div v-for="(item, i) in dataList" :key="item.id" class="item">
      <div class="left">
        <Avatar :src="avatars[i] || undefined" size="60">{{item.name}}</Avatar>
        <div>
          <div>
            <span class="name">{{item.name || '-'}}</span>
            <span class="name">{{item.sex || '-'}}</span>
            <span class="name">{{item.idNo || '-'}}</span>
          </div>
          <div>
            <span class="name">{{item.type || '-'}}</span>
            <span class="name">{{item.phone || '-'}}</span>
          </div>
        </div>
      </div>
      <div>
        <fm-btn text @click="$emit('choose', item)">选取</fm-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { getWorkerAvatar } from '@/views/resume/libs'

export default {
  props: {
    dataList: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      avatars: new Array(this.dataList.length).fill(null)
    }
  },
  watch: {
    dataList: {
      deep: true,
      handler () {
        this.loadAvatars()
      }
    }
  },
  methods: {
    loadAvatars () {
      this.avatars = new Array(this.dataList.length).fill(null)
      this.dataList.map(async (v, i) => {
        const src = v.headFileId ? await getWorkerAvatar(v.headFileId) : null
        this.$set(this.avatars, i, src)
      })
    }
  },
  mounted () {
    this.loadAvatars()
  }
}
</script>
