<style scoped lang="less">
  .header {
    background-color: #FFF;
    padding: 18px;
    box-sizing: border-box;
  }

  .content {
    padding: 0 20px 0 20px;
    margin-top: 20px;
    background-color: #FFF;
    flex: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;
  }
  .switch-btn {
    margin-right: 0;
    border-radius: 20px;
    padding: 0 10px;
    display: inline-block;
    cursor: pointer;
    i {
      margin-right: 5px;
    }
  }
  .blue-theme, .dark-theme {
    .switch-btn {
      color: #6F76B7;
      border: 1px solid #6F76B7;
      background-color: #F0F1FA;
    }
  }
  .pink-theme {
    .switch-btn {
      color: #F4628F;
      border: 1px solid #F4628F;
      background-color: rgba(244, 98, 143, .1);
    }
  }
  .yellow-theme {
    .switch-btn {
      color: #6C573C;
      border: 1px solid #6C573C;
      background-color: rgba(108, 87, 60, .1);
    }
  }
</style>

<style lang="less">
  .text-field {
    color: rgba(0, 0, 0, .7);
    font-size: 16px;
  }

  .text-value {
    color: #000;
    font-size: 16px;
  }
  .pop-doc-page {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FFF;
    border-radius: 8px;
    z-index: 9;
  }
  @keyframes loading-loop {
    from { opacity: 1; }
    from { opacity: 0.5; }
  }
  .loading {
    pointer-events: none;
    animation: loading-loop .4s linear infinite alternate;
  }
</style>

<template>
  <div style="display: flex;flex-direction: column;">
    <div class="header">
      <search />
    </div>
    <div class="content">
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <div class="text-value" style="margin: 10px 0;font-weight: 500;">我的证件墙</div>
        <div style="display: flex; align-items: center;" :class="{loading: loading.printer}">
          <span class="switch-btn" v-if="status.printer" @click="printer" style="margin-right: 10px;">
            <i class="iconfont icon-printer"></i>{{loading.printer ? '处理中...' : '确定打印'}}
          </span>
          <span class="switch-btn" v-if="status.printer" @click="checkAll" style="margin-right: 10px;">
            <i class="iconfont icon-caidan"></i>全选
          </span>
          <span class="switch-btn" v-if="status.printer" @click="checkReverse" style="margin-right: 10px;">
            <i class="iconfont icon-shuxingguanli"></i>全不选
          </span>
          <span class="switch-btn" @click="status.printer = !status.printer" style="margin-right: 10px;">
            <i v-if="!status.printer" class="iconfont icon-printer"></i>
            <i v-else class="iconfont icon-close"></i>
            {{status.printer ? '关闭打印' : '批量打印'}}
          </span>
          <span style="width: 1px;height: 26px;margin-right: 10px;background: #EEE; display: inline-block;"></span>
          <router-link tag="span" :to="{
            name: 'resume.details.base',
            query: { id: id }
          }" class="switch-btn"><i class="iconfont icon-switch"></i>简历模式</router-link>
        </div>
      </div>
      <div style="display: flex;flex: 1 1 0%;height: 0;position: relative;">
        <div style="width: 285px;height: 100%;overflow: auto;">
          <Info />
        </div>
        <div style="flex: 1 1 0%;overflow: auto;height: 100%;border-left: 1px solid #EEE;">
          <Lists :printer="status.printer" ref="list" />
        </div>
      </div>
    </div>
    <div v-if="status.print" class="pop-doc-page">
      <print @quit="status.print = false" :source="sources" />
    </div>
  </div>
</template>

<script>
import Search from '@/views/resume/components/details/search'
import Info from '@/views/resume/components/preview/info'
import Lists from '@/views/resume/components/preview/lists'
import Print from '@/components/base/file/print.vue'

import { getWorkerInfo } from '@/views/resume/libs'

export default {
  components: { Search, Info, Lists, Print },
  data () {
    return {
      sources: [],
      status: {
        printer: false,
        print: false
      },
      loading: {
        printer: false
      }
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    }
  },
  watch: {
    id: {
      immediate: true,
      handler (v) {
        if (!v && this.$route.name.indexOf('resume.preview') === 0) {
          this.$Message.error('参数缺失')
          this.$router.back()
        } else {
          this.loadData()
        }
      }
    }
  },
  methods: {
    checkAll () {
      this.$refs.list.checkAll()
    },
    checkReverse () {
      this.$refs.list.checkReverse()
    },
    async loadData () {
      await getWorkerInfo(this.id)
    },
    async printer () {
      if (!this.loading.printer) {
        this.loading.printer = true
        const promises = this.$refs.list.certs.map(async item => {
          let previewList = []
          if (typeof item.sources.previews === 'function') {
            const list = item.sources.previews()
            if (list instanceof Promise) {
              previewList = await list
            } else {
              previewList = list
            }
          } else if (Array.isArray(item.sources.previews)) {
            previewList = item.sources.previews
          } else {
            previewList = item.sources.covers
          }
          return previewList.filter((v, i) => item.checkedList.includes(i))
        })
        const sources = await Promise.all(promises)
        this.loading.printer = false
        this.sources = sources.flat().map(v => v.src)
        this.status.print = true
      }
    }
  },
  mounted () {
    this.loadData()
    this.$store.dispatch('loadPositionList')
    this.$store.dispatch('loadOrgList')
  }
}
</script>
