<style scoped></style>

<template>
  <div>
    <r-card v-for="(item, index) in certs" :key="item.name">
      <span slot="title" style="display: flex; align-items: center;">
        <fm-checkbox style="margin-left: 10px;" size="large" @click.native="() => itemCheckedAll(item, index)" :value="item.checked" :label="index" v-if="printer">
          {{item.label}}
        </fm-checkbox>
        <template v-else>
          {{item.label}}
        </template>
      </span>
      <r-imgs v-if="item.sources" :checked="item.checkedList" @checked="(list) => onImgChecked(item, index, list)" :checkbox="printer" :covers="item.sources.covers" :previews="item.sources.previews" :name="item.sources.name" />
    </r-card>
  </div>
</template>

<script>
import { getWorkerInfo } from '@/views/resume/libs'
import { workerCertificateRequest, rewPunRequest, trainRequest } from '@/api'
import { getEduData } from '@/views/resume/libs'
import RCard from '@/views/resume/components/card'
import RImgs from '@/views/resume/components/imgs'
import store from '@/store'
import { loadImgs } from '@/components/base/file/libv2'

const certs = [
  {
    label: '学历证书', name: 'edu', async get (id) {
      const data = await getEduData(id)
      return loadImgs(data, (item) => item.type || '学历证书')
    }
  },
  {
    label: '专业技术职务聘任证书', name: 'hire', async get (id) {
      const data = await workerCertificateRequest.get({ type: 'pro_hire', workerId: id })
      return loadImgs(data, (item) => item.hireWorker || '证书')
    }
  },
  {
    label: '职业资格证书', name: 'doc_qua', async get (id) {
      const data = await workerCertificateRequest.get({ type: 'doctor_qua', workerId: id })
      const types = store.getters.selectDatas['qualification_certificate_type'];
      return loadImgs(data, (item) => {
        const type = types.find(v => v.key === item.xlmc)
        const xlmcText = type ? type.label : null
        return xlmcText || '证书'
      })
    }
  },
  {
    label: '专业技术资格证书', name: 'qua', async get (id) {
      const data = await workerCertificateRequest.get({ type: 'pro_qua', workerId: id })
      return loadImgs(data, (item) => item.certificateTitle || '证书')
    }
  },
  {
    label: '执业证书', name: 'pra', async get (id) {
      const data = await workerCertificateRequest.get({ type: 'doctor_pra', workerId: id })
      return loadImgs(data, (item) => item.mType || '证书')
    }
  },
  {
    label: '其他证书', name: 'other', async get (id) {
      const data = await workerCertificateRequest.get({ type: 'other', workerId: id })
      return loadImgs(data, (item) => item.mType || '证书')
    }
  },
  {
    label: '身份证', name: 'idcard', async get (id) {
      const data = await getWorkerInfo(id)
      const items = []
      if (data.idCardFrontFileId) {
        items.push({
          name: '正面',
          fileIds: data.idCardFrontFileId
        })
      }
      if (data.idCardBackFileId) {
        items.push({
          name: '背面',
          fileIds: data.idCardBackFileId
        })
      }
      return loadImgs(items, (item) => item.name || '身份证')
    }
  },
  {
    label: '奖励信息', name: 'rew', async get (id) {
      const data = await rewPunRequest.get({ workerId: id, type: 'rew' })
      return loadImgs(data, (item) => item.title || '证书')
    }
  },
  {
    label: '培训信息', name: 'train', async get (id) {
      const data = await trainRequest.get({ workerId: id, type: 'rew' })
      return loadImgs(data, (item) => item.pxxm || '证书')
    }
  }
]

export default {
  components: { RCard, RImgs },
  props: {
    printer: { type: Boolean, default: false }
  },
  data () {
    return {
      certs: []
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    }
  },
  methods: {
    checkAll () {
      this.certs = this.certs.map(item => {
        item.checked = true
        item.checkedList = item.sources.covers.map((v, i) => i)
        return item
      })
    },
    checkReverse () {
      this.certs = this.certs.map(item => {
        item.checked = false
        item.checkedList = []
        return item
      })
    },
    itemCheckedAll (item, index) {
      item.checked = !item.checked

      if (item.checked) {
        item.checkedList = item.sources.covers.map((v, i) => i)
      } else {
        item.checkedList = []
      }

      this.certs.splice(index, 1, item)
    },
    onImgChecked (item, index, list) {
      item.checkedList =  list
      item.checked =  list.length > 0
      this.certs.splice(index, 1, item)
    },
    async loadData () {
      this.certs = certs.map(v => {
        return {
          label: v.label,
          name: v.name,
          sources: null,
          checked: false,
          checkedList: []
        }
      })

      const promise = certs.map(async (v, i) => {
        this.certs[i].sources = await v.get(this.id)
        this.certs.splice(i, 1, this.certs[i])
      })
      
      this.$Loading.start()
      await Promise.all(promise)
      this.$Loading.finish()
    }
  },
  mounted () {
    this.loadData()  
  }
}
</script>
