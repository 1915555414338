<style scoped lang="less">
  .avatar {
    width: 144px;
    height: 144px;
    border-radius: 72px;
    object-fit: cover;
  }
  .text-value {
    font-weight: 500;
  }
  .row {
    margin-top: 15px;
  }
</style>

<template>
  <div class="preview-info">
    <div style="display: flex; flex-direction: column;justify-content: center; align-items: center;">
      <img :src="src" class="avatar" />
      <span style="margin-top: 18px;" class="text-value">{{data.name || '-'}}</span>
    </div>
    <div class="row">
      <span class="text-field">职务：</span>
      <span class="text-value">{{positionText || '-'}}</span>
    </div>
    <div class="row">
      <span class="text-field">工龄：</span>
      <span class="text-value">{{data.maritalStatus || '-'}}</span>
    </div>
    <div class="row">
      <span class="text-field">是否在编：</span>
      <span class="text-value">{{data.type || '-'}}</span>
    </div>
    <div class="row">
      <span class="text-field">在岗状态：</span>
      <span class="text-value">{{data.jobTypeC || '-'}}</span>
    </div>
    <div class="row">
      <span class="text-field">人员类别：</span>
      <span class="text-value">{{data.jobType || '-'}}</span>
    </div>
  </div>
</template>

<script>
import { getWorkerAvatar, getWorkerInfo } from '@/views/resume/libs'

export default {
  data () {
    return {
      src: null,
      data: {}
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    },
    positionList () {
      return this.$store.getters.positionList
    },
    positionMap () {
      let data = {}
      this.positionList.forEach(v => data[v.key] = v.label)
      return data
    },
    positionText () {
      return this.positionMap[this.data.positionId] || null
    }
  },
  watch: {
    id () {
      // this.loadAvatar()
      this.loadInfo()
    }
  },
  methods: {
    async loadAvatar () {
      this.src = this.data && this.data.headFileId ? await getWorkerAvatar(this.data.headFileId) : null
    },
    async loadInfo () {
      this.data = await getWorkerInfo(this.id)
      this.loadAvatar()
    }
  },
  mounted () {
    this.loadAvatar()
    this.loadInfo()
  }
}
</script>
